<footer>
  <div class="information">
    <div class="logo">
      <a href="#"><img class="image" src="/assets/images/logo.svg"></a>
      <ul>
        <li class="maitre-adresse">
          <p class="text text-white">Maître Catherine ROCHAIX-CELTON</p>
          <p class="text text-white">Maître Yannick RIOU</p>
        </li>
      </ul>
    </div>
    <div class="block-texte">
      <p class="text-wine">NOTRE EXPERTISE</p>
      <ul>
        <li>
          <a class="text text-white" href="/services">Du droit de la famille</a>
        </li>
        <li>
          <a class="text text-white" href="/services">Gestion du patrimoine</a>
        </li>
        <li>
          <a class="text text-white" href="/services">Des affaires et des sociétés</a>
        </li>
        <li>
          <a class="text text-white" href="/services">Du droit immobilier et de la fiscalité</a>
        </li>
      </ul>
    </div>
    <div class="block-texte">
      <p class="text-wine">NAVIGATION</p>
      <div class="text-navigation">
        <div class="navigation-column">
          <ul>
            <li>
              <a class="text text-white" href="#">Accueil</a>
            </li>
            <li>
              <a class="text text-white" href="/office">L'office</a>
            </li>
            <li>
              <a class="text text-white" href="/annonces-immobiliere">Annonces immobilières</a>
            </li>
            <li>
              <a class="text text-white" href="/expertises">Expertises</a>
            </li>
            <li>
              <a class="text text-white" href="/services">Services</a>
            </li>
          </ul>
        </div>
        <div class="navigation-column">
          <ul>
            <li>
              <a class="text text-white" href="/actualites">Actualités</a>
            </li>
            <li>
              <a class="text text-white" href="/tarifs">Tarifs</a>
            </li>
            <li>
              <a class="text text-white" href="/dossier">Dossier</a>
            </li>
            <li>
              <a class="text text-white" href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="block-texte">
      <ul>
        <li>
          <p class="text-wine">RENNES</p>
          <p class="text text-white">Rue de la Terre Victoria, Bâtiment B</p>
          <p class="text text-white">Parc Edonia</p>
          <p class="text text-white">35760 SAINT-GREGOIRE</p>
        </li>
      </ul>
      <div class="reseaux">
        <a href="https://fr.linkedin.com/company/breizh-notaires" target="_blank"><i class="fa fa-linkedin"></i></a>
      </div>
    </div>
  </div>
  <div class="mention">
    <a href="https://www.agence-11h10.fr/" target="_blank">Agence 11h10 Web & Com</a>
    <p>-</p>
    <a href="/mentions-legales">Mentions légales</a>
  </div>

</footer>
