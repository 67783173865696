<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">CONTACT</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
</section>

<section class="section-form">
  <div class="block-text-image">
    <div class="block-image">
      <div class="map">
        <google-map [options]="{center: {lat: 48.1529834, lng: -1.6987197}, zoom: 17}" width="100%" height="100%">
          <map-marker [position]="{lat:48.1530453, lng: -1.6978492}"></map-marker>
        </google-map>
      </div>
    </div>
    <div class="block-text">
      <p class="text text-dark-wine">
        Ouvert du lundi au vendredi de 8h45 à 12h45 et de 14h00 à 18h15 - En dehors de ces horaires et le samedi matin, sur rendez-vous.<br/><br/>
        Parking gratuit accessible sur demande<br/>
        Accès aux personnes à mobilité réduite.<br/><br/>
        Pour accèder à nos bureaux en bus : ligne 78 , arrêt Alphasis à 20 mètres.
      </p>
      <form class="form" (ngSubmit)="onFormSubmit(contactForm.value)" #contactForm="ngForm">
        <div class="form-group my-3">
          <input type="text" class="form-control" placeholder="Nom" [(ngModel)]="name" name="name" required>
        </div>
        <div class="form-group my-3">
          <input type="text" class="form-control" placeholder="Prénom" [(ngModel)]="firstName" name="firstName" required>
        </div>
        <div class="form-group my-3">
          <input type="text" class="form-control" placeholder="Téléphone" [(ngModel)]="phone" name="phone" required>
        </div>
        <div class="form-group my-3">
          <input type="email" class="form-control" placeholder="Mail" [(ngModel)]="email" [email]="true" name="email" required>
        </div>
        <div class="form-group my-3">
          <input type="text" class="form-control" placeholder="Code Postal" [(ngModel)]="postalCode"  name="postalCode" required>
        </div>
        <div class="form-group my-3">
          <textarea class="form-control" placeholder="Comment pouvons-nous vous aider" [ngStyle]="{height: '200px'}" [(ngModel)]="content" name="content"></textarea>
        </div>

        <div class="form-group d-flex">
          <input class="checkbox" type="checkbox" name="consentement" [(ngModel)]="consentement">
          <p class="text-consentement">En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande. <a class="checkbox-consentement text-wine" target="_blank" href="/mentions-legales">* En savoir plus.</a></p>
        </div>

        <div class="form-group group-button">
          <button type="submit" class="button text-white">ENVOYER</button>
        </div>

        <div *ngIf="formSuccess" class="form-group my-3 text-success text-center"><b><u>Le formulaire a été envoyé avec succès.</u></b></div>
        <div *ngIf="formError" class="form-group my-3 text-danger text-center"><b><u>Une erreur est survenue.</u></b></div>
      </form>
    </div>
  </div>
</section>

<app-footer></app-footer>
