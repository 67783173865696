import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { OfficeComponent } from './pages/office/office.component';
import { AnnoncesImmobilieresComponent } from './pages/annonces-immobilieres/annonces-immobilieres.component';
import { ExpertisesComponent } from './pages/expertises/expertises.component';
import { ServicesComponent } from './pages/services/services.component';
import { ActualitesComponent } from './pages/actualites/actualites.component';
import { TarifsComponent } from './pages/tarifs/tarifs.component';
import { DossierComponent } from './pages/dossier/dossier.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { ActualitesDetailComponent } from './pages/actualites-detail/actualites-detail.component';
import { AnnoncesImmobilieresDetailComponent } from './pages/annonces-immobilieres-detail/annonces-immobilieres-detail.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'office', component: OfficeComponent },
  { path: 'annonces-immobilieres', component: AnnoncesImmobilieresComponent },
  { path: 'annonces-immobilieres/:slug', component: AnnoncesImmobilieresDetailComponent },
  { path: 'expertises', component: ExpertisesComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'actualites', component: ActualitesComponent },
  { path: 'actualites/:slug', component: ActualitesDetailComponent },
  { path: 'tarifs', component: TarifsComponent },
  { path: 'dossier', component: DossierComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  { path: '**', component: PageNotFoundComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
