<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">{{annonce.designation}} - {{annonce.nbPieces}} pièce(s) - {{annonce.surfaceHabitable}} m² - {{annonce.ville}} ({{annonce.departement}})</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
</section>

<section class="section-annonce">
  <div class="block-button">
    <a href="/annonces-immobilieres" class="button text-white">Retour aux annonces</a>
  </div>
  <div class="detail-annonce">
    <div class="detail-gauche">
      <div class="part-carousel">
        <div id="carouselExample" class="carousel slide">
          <div class="carousel-inner" >
            <div class="carousel-item" *ngFor="let image of annonce.images; let isFirst = first" [ngClass]="{'active': isFirst}">
              <div class="image-annonce" style="background-image: url({{image.file}});"></div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div class="description">
        <div class="contact">
          <p class="text-black">Téléphone : <a class="text-black" href="tel:0299687272">02 99 68 72 72</a></p>
          <p class="text-black">Email : <a class="text-black" href="mailto:breizhnotaires.saintgregoire@notaires.fr">breizhnotaires.saintgregoire@notaires.fr</a></p>
          <hr class="border border-1 opacity-100 border-wine">
        </div>
        <p class="text-black">
          {{annonce.designation}} {{annonce.transaction}} - {{annonce.ville}} ({{annonce.departement}})<br/><br/>
          {{annonce.annonce}}
        </p>
      </div>
    </div>


    <div class="detail-droite">
      <div class="annonce">
        <div class="description-annonce">
          <div class="description">
            <div class="price">
              <h3 class="text-wine">{{annonce.prix | number }} €</h3>
              <hr class="border border-1 opacity-100 border-wine">
            </div>
            <div>
              <p class="text-charge">Dont prix de vente : 585 000 €<br/>Dont HN* : 20 550 € (3.5%)<br/>Charge acquéreur</p>
              <p class="text-piece">Surface habitable en m²<br/><b>{{annonce.surfaceHabitable}} m²</b></p>
              <p class="text-piece">Surface séjour en m²<br/><b>{{annonce.surfaceSejour}} m²</b></p>
              <p class="text-piece">Surface terrain<br/><b>{{annonce.surfaceTerrain}} ha</b></p>
              <p class="text-piece">Nombre de pièce <br/><b>{{annonce.nbPieces}}</b></p>
              <p class="text-piece">Nombre de chambres <br/><b>{{annonce.nbChambres}}</b></p>
            </div>
            <div class="block-image">
              <app-dpe-ges [dpeValue]="annonce.dpenrj" [gesValue]="annonce.dpeges"></app-dpe-ges>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</section>

<app-footer></app-footer>





