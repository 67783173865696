import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActualiteService } from 'src/app/_services/actualite.service';

@Component({
  selector: 'app-actualites-detail',
  templateUrl: './actualites-detail.component.html',
  styleUrls: ['./actualites-detail.component.scss']
})
export class ActualitesDetailComponent {

  actu: any  = [];
  slug: any;

  constructor(
    private actualiteService: ActualiteService,
    private route: ActivatedRoute,
  ) {
    this.slug = this.route.snapshot.paramMap.get('slug');
  }

  ngOnInit(): void {
    this.actualiteService.getOneActualite(this.slug).subscribe(data => {
      this.actu = data;
    })
  }

}
