import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TarifService {

  constructor(private http: HttpClient) { }

  downloadPdf() {
    return this.http.get("/assets/pdf/tarif_BREIZH_NOTAIRES.pdf", {
      responseType: 'arraybuffer',
      headers: new HttpHeaders().append('Content-Type', 'application/pdf'),
    });
  }
}
