<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">ACTUALITÉS</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
  <p class="text text-dark-wine">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod…</p>
</section>

<section class="section-block">
  <a routerLink="{{actu.slug}}" class="block-text-image" *ngFor="let actu of actus | pagination: actualPage">
    <div class="w-100">
      <div class="block-image" style="background-image: url({{actu.image}});" >
      </div>
      <div class="block-text text-white">
        <h2 class="font-title"><b>{{actu.title}}</b></h2>
        <p [innerHTML]="doHtmlDisplay(actu.content, 400) | sanitizeHtml"></p>
        <a class="decoration">Lire la suite</a>
      </div>
    </div>
    <div class="date text-white">
      <hr class="border border-1 opacity-75 border-white">
      <p>{{actu.dateDebut | date:"dd-MM-Y"}}</p>
    </div>
  </a>
  <div class="pagination">
    <button [ngClass]="actualPage != 1 ? '': 'no-click'" (click)="precedent()">Précédent</button>

    <button (click)="nbRedirection(1)" [ngClass]="actualPage == 1 ? 'actual-page': ''">1</button>

    <button *ngIf="beforePage - 1 > 1" class="no-click text-black">...</button>

    <button *ngIf="beforePage > 1" (click)="nbRedirection(beforePage)">{{beforePage}}</button>

    <button *ngIf="actualPage != 1 && actualPage != nbPage" class="actual-page" (click)="nbRedirection(actualPage)">{{actualPage}}</button>

    <button *ngIf="afterPage < nbPage" (click)="nbRedirection(afterPage)">{{afterPage}}</button>

    <button *ngIf="afterPage + 1 < nbPage" class="no-click text-black">...</button>

    <button (click)="nbRedirection(nbPage)"  [ngClass]="actualPage == nbPage ? 'actual-page': ''" *ngIf="actus.length != 1">{{nbPage}}</button>

    <button [ngClass]="actualPage < nbPage ? '': 'no-click'" (click)="suivant()">Suivant</button>
  </div>
</section>

<app-footer></app-footer>
