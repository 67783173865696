<app-header></app-header>
<section class="section-banniere">
  <div class="text-banniere text-white">
    <h1 class="font-title big-title">Office de notaires à Saint-Grégoire.</h1>
    <hr class="border border-1 opacity-100 border-wine">
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore</p>
    <a href="/office" class="button-office text-white">DÉCOUVREZ L'OFFICE</a>
  </div>
</section>


<section class="section-equipe d-flex flex-lg-row flex-column">
  <div class="equipe-image" style="background-image: url(https://prismeoffice.adnov.fr/media/view/0/310/0/0/1b0725ddea8cb85cd1620e436f89ea1f17cb065e.jpg);">
  </div>
  <div class="flex-fill d-flex flex-column justify-content-between" id="order-section">
    <div class="d-flex flex-column align-items-center order-presentation">
      <div class="presentation">
        <h2 class="title text-wine font-title small-title"><b>Lorem ipsum dolor sit amet, consetetur…</b></h2>
        <p class="text text-dark-wine">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit</p>
        <hr class="border border-1 opacity-100 border-wine">
      </div>
    </div>

    <div class="d-flex flex-lg-row flex-column justify-content-center align-items-lg-center align-items-start order-icon">
      <a href="/contact" class="d-flex flex-row flex-lg-column align-items-center icon-hover m-3 p-3 text-decoration-none">
        <img class="icon" src="/assets/images/logo-plume.svg">
        <p class="text-hover"><b>CONTACTEZ-NOUS</b></p>
      </a>
      <a href="" class="d-flex flex-row flex-lg-column align-items-center icon-hover m-3 p-3 text-decoration-none">
        <img class="icon" src="/assets/images/logo-plume.svg">
        <p class="text-hover"><b>ESTIMATIONS</b></p>
      </a>
      <a href="/annonces-immobilieres" class="d-flex flex-row flex-lg-column align-items-center icon-hover m-3 p-3 text-decoration-none">
        <img class="icon" src="/assets/images/logo-plume.svg">
        <p class="text-hover"><b>ANNONCES</b></p>
      </a>
    </div>

    <div class="max-content order-plume">
      <a href="/office" class="d-flex align-items-end text-decoration-none">
        <p class="text-black text-plume w-auto"><b>DÉCOUVREZ L’ÉQUIPE DE L'OFFICE</b></p>
        <img class="logo-plume w-auto" src="/assets/images/logo-plume.svg">
      </a>
      <hr class="border border-1 m-0 opacity-100 border-black">
    </div>
  </div>
</section>


<section class="section-annonce">
  <div class="d-lg-flex">
    <div class="part-text text-white">
      <h2 class="font-title small-title">ANNONCES IMMOBILIERES</h2>
      <hr class="border border-1 opacity-100 border-wine">
      <p class="text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.<br/> Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum</p>
      <a href="/annonces-immobilieres" class="button-annonce text-white responsive-none">VOIR TOUTES LES ANNONCES</a>
    </div>
    <div class="part-carousel">
      <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let annonce of annonces; let isFirst = first" [ngClass]="{'active': isFirst}">
            <div class="annonce">
              <div class="image-annonce" style="background-image: url({{annonce.images[0].file}});"></div>
              <div class="description-annonce">
                <div class="description">
                  <div class="price">
                    <h3 class="text-wine">{{annonce.prix | number }} €</h3>
                    <hr class="border border-1 opacity-100 border-wine">
                  </div>
                  <p class="text-piece"><b>{{annonce.designation}}<br/>{{annonce.nbPieces}} pièce(s) - {{annonce.surfaceHabitable}} m²<br/>{{annonce.ville}} ({{annonce.departement}})</b></p>
                  <p class="text-charge">Dont prix de vente : 585 000 €<br/>Dont HN* : 20 550 € (3.5%)<br/>Charge acquéreur</p>
                </div>
                <a href="/annonces-immobilieres/{{annonce.slug}}" class="fiche">
                  <img class="icon" src="/assets/images/logo-plume.svg">
                  <p class="text-wine">VOIR LA FICHE</p>
                  <hr class="border border-1 opacity-100 border-wine">
                </a>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="part-text responsive-block">
      <a href="/annonces-immobilieres" class="button-annonce text-white">VOIR TOUTES LES ANNONCES</a>
    </div>
  </div>
</section>

<section class="section-service">
  <div class="d-lg-flex justify-content-between">
    <div class="title">
      <h2 class="text-white font-title small-title">SERVICES EN LIGNE</h2>
      <hr class="border border-1 opacity-100 border-white">
    </div>
    <div class="d-flex flex-column button">
      <a href="/tarifs" class="button-service text-white">TARIFS OFFICE</a>
      <a href="/tarifs" class="button-service text-white">FRAIS ACQUISITION</a>
      <a href="/tarifs" class="button-service text-white">CAPACITÉ D'EMPRUNT</a>
    </div>
    <div class="text">
      <hr class="border border-1 opacity-100 border-white">
      <p class="text-white">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod…</p>
      <hr class="border border-1 opacity-100 border-white">
    </div>
  </div>
</section>

<section class="section-adresse d-flex flex-lg-row flex-column">
  <div class="flex-fill map">
    <google-map [options]="{center: {lat: 48.1529834, lng: -1.6987197}, zoom: 17}" width="100%" height="100%">
      <map-marker [position]="{lat:48.1530453, lng: -1.6978492}"></map-marker>
    </google-map>
  </div>
  <div class="flex-fill text text-white font-title">
    <div class="index-max">
      <h2 class="small-title"><b>HERMINE NOTAIRES</b></h2>
      <h3><b>Office notarial</b></h3>
    </div>
    <div class="index-max">
      <p>
        Rue de la Terre Victoria, Bâtiment B<br/>
        Parc Edonia<br/>
        35760 Saint-Grégoire
      </p>
    </div>
    <div class="index-max">
      <a class="text-white" href="tel:0299687272">02 99 68 72 72</a><br/>
      <a class="text-white" href="mailto:breizhnotaires.saintgregoire@notaires.fr">breizhnotaires.saintgregoire@notaires.fr</a>
    </div>
    <div class="button-contact index-max">
      <a href="/contact" class="contact"><b>CONTACTEZ-NOUS</b></a>
      <hr class="border border-1 m-0 opacity-100 border-wine">
    </div>
  </div>

</section>
<app-footer></app-footer>
