<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">SERVICES</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
  <p class="text text-dark-wine">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod…</p>
</section>

<section class="section-block">
  <a href="https://www.notaires.fr/fr/immobilier" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Optimiser votre patrimoine</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/famille" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Se marier, se pacser</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/immobilier" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Vendre un bien immobilier</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/famille" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Adopter un enfant</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/famille" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Se séparer, divorcer</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/donation" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Procéder à des donations</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/succession" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Protéger et prévoir l’avenir de vos proches</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/succession" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Vous vous posez des questions sur l’héritage</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/immobilier" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Louer un bien immobilier</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/famille" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Vivre à l'étranger</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/immobilier" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Entreprendre</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.notaires.fr/fr/immobilier" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Acheter une maison</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.immobilier.notaires.fr/fr/frais-de-notaire" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Evaluer vos frais d'achat</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.anil.org/outils/outils-de-calcul/diagnostic-de-financement/" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Vérifier vos capacités d'emprunt</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="https://www.anil.org/outils/outils-de-calcul/echeancier-dun-pret" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Calculer vos remboursements de prêt</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>

  <a href="http://plus-values.notaires.fr/simulateur/index.xhtml" target="_blank"  class="block-text-image text-white">
    <div class="overflow">
      <img class="icon" src="/assets/images/logo-plume.svg">
      <div class="block-image"></div>
    </div>
    <div class="block-text font-title">
      <p><b>Calculer vos plus-values immobilières</b></p>
      <hr class="border border-1 opacity-100 border-wine">
    </div>
  </a>
</section>

<app-footer></app-footer>
