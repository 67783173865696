import { Component } from '@angular/core';
import { TarifService } from '../../_services/tarif.service';

@Component({
  selector: 'app-tarifs',
  templateUrl: './tarifs.component.html',
  styleUrls: ['./tarifs.component.scss']
})
export class TarifsComponent {

  constructor(private tarifService: TarifService){}

  downloadPdf(){
    this.tarifService.downloadPdf().subscribe((response: ArrayBuffer) => this.download(response, 'application/pdf', 'tarif_BREIZH_NOTAIRES.pdf'))
  }

  download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
    const file: Blob = new Blob([binaryData], {type: fileType});
    const url: string = window.URL.createObjectURL(file);
    const anchorElement: HTMLAnchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = url;
    anchorElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  }

}
