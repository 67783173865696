<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">OFFICE</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
  <p class="text text-dark-wine">
    L’office notarial installé sur la Commune depuis 2003 est situé à Saint-Grégoire, commune de Rennes-Métropole, limitrophe de Rennes au nord, Montgermont,
    La Chapelle des Fougeretz, Melesse, La Mézière, Betton, Thorigné-Fouillard, d’accès rapide vers Pacé, Cesson-Sévigné, Chantepie et Acigné Saint-Grégoire,
    forte de 10 000 habitants, est une ville dynamique, à taille humaine, au cadre environnemental agréable. Maître Catherine ROCHAIX-CELTON et Maître Yannick RIOU,
    successeurs de Maître PONS et de Maître CAUSSIN, notaires à Saint-Grégoire, interviennent dans tous les domaines du droit notarial et notamment :
  </p>
  <ul class="text-liste text-dark-wine">
    <li>
      <p class="text-liste text-dark-wine">
        Le droit de la famille et du patrimoine : contrat de mariage, donations, partages, successions, testaments, divorces, pacs, mandats de protection future
        ou à effet posthume, indivisions, adoptions.
      </p>
    </li>
    <li>
      <p class="text-liste text-dark-wine">
        Le droit immobilier et de la fiscalilté
      </p>
    </li>
    <li>
      <p class="text-liste text-dark-wine">
        Le droit des affaires et de l’entreprise L’office notarial assure également un service de négociation (ventes, locations) :
        bien estimer pour bien vendre ou bien acquérir (maisons, appartements, terrains, locaux professionnels et commerciaux)
      </p>
    </li>
  </ul>
  <p class="text text-dark-wine">
    L’étude se compose de Maître Catherine ROCHAIX-CELTON et de Maître Yannick RIOU, Notaires associés, de notaires -assistants, de collaborateurs juridiques
    et comptables. L’office notarial est actuellement labelisé REV TEGoVA et bientôt NCF (Notaire conseil aux Familles)
  </p>
</section>

<section class="section-text">
  <div class="d-flex justify-content-end">
    <p class="block-text text-dark-wine">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod…</p>
  </div>
</section>

<app-footer></app-footer>
