import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BiensService } from 'src/app/_services/biens.service';

@Component({
  selector: 'app-annonces-immobilieres',
  templateUrl: './annonces-immobilieres.component.html',
  styleUrls: ['./annonces-immobilieres.component.scss']
})
export class AnnoncesImmobilieresComponent {

  annonces: any  = [];

  paramsTransaction: any = [
    'vente',
    'location',
  ];

  searchTransaction : string = "vente"

  constructor(
    private biensService: BiensService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.searchTransaction = this.checkTransaction(params['transaction']);
      }
    );
    this.biensService.getAllBiens(this.searchTransaction).subscribe(data => {
      this.annonces = data;
    })
  }

  checkTransaction(status: string): string {
    if(status && this.paramsTransaction.includes(status)) {
      return status;
    }

    return 'vente';
  }

  getBienWithTransaction(value: string){
    this.searchTransaction = value;
    this.biensService.getAllBiens(this.searchTransaction).subscribe(data => {
      this.annonces = data;
    })
  }
}
