<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">TARIFS</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
  <p class="text text-dark-wine">
    Frais de négociation en cas de choix du système de la vente amiable de gré à gré :
  </p>
  <ul class="text-liste text-dark-wine">
    <li>
      <p class="text-liste text-dark-wine">
        de 0 à 100.000 € : 5% HT
      </p>
    </li>
    <li>
      <p class="text-liste text-dark-wine">
        au-delà de 100.000 € : 2,5% HT
      </p>
    </li>
  </ul>

  <p class="text text-dark-wine">
    Frais de négociation en cas de choix du système de la vente amiable par adjudication Vente par adjudication sans surenchère,
    sur le montant de la dernière enchère :
  </p>
  <ul class="text-liste text-dark-wine">
    <li>
      <p class="text-liste text-dark-wine">
        Si réalisée par HERMINE Notaires seule : 4% HT
      </p>
    </li>
    <li>
      <p class="text-liste text-dark-wine">
        Si réalisée en partenariat avec MIN-NOT, dont le siège est à VENELLES (13107), 95 Avenue des Logissons :
      </p>
      <ul class="text-liste text-dark-wine">
        <li>
          <p class="text-liste text-dark-wine">
            Pour HERMINE Notaires : 2% HT
          </p>
        </li>
        <li>
          <p class="text-liste text-dark-wine">
            Pour MIN-NOT : 2% HT (avec un minimum de 800 € HT)
          </p>
        </li>
      </ul>
    </li>
  </ul>

  <p class="text text-dark-wine">
    Vente par adjudication sur surenchère, sur le montant de la dernière enchère :
  </p>
  <ul class="text-liste text-dark-wine">
    <li>
      <p class="text-liste text-dark-wine">
        Si réalisée par HERMINE Notaires seule : 4% HT
      </p>
    </li>
    <li>
      <p class="text-liste text-dark-wine">
        Si réalisée en partenariat avec MIN-NOT, dont le siège est à VENELLES (13107), 95 Avenue des Logissons :
      </p>
      <ul class="text-liste text-dark-wine">
        <li>
          <p class="text-liste text-dark-wine">
            Pour HERMINE Notaires : 2% HT
          </p>
        </li>
        <li>
          <p class="text-liste text-dark-wine">
            Pour MIN-NOT : 2,5% HT (avec un minimum de 1.200 € HT)
          </p>
        </li>
      </ul>
    </li>
  </ul>
</section>

<section class="section-block">
  <div class="block-file">
    <div class="text-file">
      <i class="fa fa-file-pdf-o icon"></i>
      <div>
        <p class="text-black"><b>Fiche des tarifs de l'office</b></p>
        <p class="text-black">Format : pdf | Poids : 34Ko</p>
      </div>
    </div>
    <a type="button" class="button" (click)="downloadPdf()">TÉLÉCHARGER LE DOCUMENT</a>
  </div>
</section>

<app-footer></app-footer>
