import { Component, OnInit } from '@angular/core';
import { ActualiteService } from 'src/app/_services/actualite.service';

@Component({
  selector: 'app-actualites',
  templateUrl: './actualites.component.html',
  styleUrls: ['./actualites.component.scss']
})
export class ActualitesComponent implements OnInit {

  actus:any  = [];
  beforePage: number = 0;
  actualPage: number = 1;
  afterPage: number = 2;
  nbPage: number = 1;
  itemForPage: number = 12;

  constructor(
    private actualiteService: ActualiteService
  ) {}

  ngOnInit(): void {
    this.actualiteService.getAllActualites().subscribe(data => {
      this.actus = data.sort((a: any, b: any) => new Date(b.dateDebut).valueOf() - new Date(a.dateDebut).valueOf());
      this.nbPage = Math.floor(this.actus.length / this.itemForPage)
    })
  }

  doHtmlDisplay(text: string, limit: number) {
    if (text.length > limit) {
      text = text.substring(0, limit);
    } else {
     text;
    }
    return text;
   }

   precedent(): void{
    this.beforePage = this.actualPage - 2
    this.actualPage = this.actualPage - 1
    this.afterPage = this.actualPage + 1
   }

   suivant(): void{
    this.beforePage = this.actualPage
    this.actualPage = this.actualPage + 1
    this.afterPage = this.actualPage + 2
   }

   nbRedirection(nb: number): void{
    this.beforePage = nb - 1
    this.actualPage = nb
    this.afterPage = nb + 1
   }
}
