import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactService } from '../../_services/contact.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  name: string = "";
  firstName: string = "";
  phone: string = "";
  email: string = "";
  postalCode: string = "";
  content: string = "";
  consentement: boolean = false;
  formSuccess: boolean = false;
  formError: boolean = false;

  constructor(
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
  }

  onFormSubmit(contactForm:NgForm){
    if (this.consentement == true) {
      this.contactService.sendContact(contactForm).subscribe(
        (data) => {
          console.log(data)
          this.formSuccess = true;
        },
        (error) => {
          console.log(error);
          this.formError = true;
          ;
        }
      );
    } else {
      alert("Veuillez acceptez les conditions");
    }
  }


}
