import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const API = `${environment.api}/api`;

@Injectable({
  providedIn: 'root'
})
export class ActualiteService {

  constructor(private http: HttpClient) { }

  getAllActualites(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
      })
    };

    return this.http.get(API + '/actualites/displayed', httpOptions);
  }

  getOneActualite(slug: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
      })
    };

    return this.http.get(API + '/actualites/displayed/' + slug, httpOptions)
  }
}
