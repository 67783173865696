<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">Page non trouvée</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
</section>

<section class="section-not-found">
  <div class="block-not-found">
    <p class="text text-dark-wine">Désolé, cette page a peut-être été supprimée ou déplacée.<br/>Pour retrouver votre chemin :</p>
    <a href="#" class="button-not-found text-white responsive-none">Retour à l'accueil</a>
  </div>
</section>

<app-footer></app-footer>
