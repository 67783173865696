<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">ANNONCES IMMOBILIERES</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
  <div class="block-button">
    <a (click)="getBienWithTransaction('vente')" class="button" [ngClass]="{'active': searchTransaction === 'vente'}">ACHETER</a>
    <a (click)="getBienWithTransaction('location')" class="button" [ngClass]="{'active': searchTransaction === 'location'}">LOUER</a>
  </div>
</section>

<section class="section-annonce">

  <div class="annonce" *ngFor="let annonce of annonces">
    <div class="image-annonce" style="background-image: url({{annonce.images[0].file}});"></div>
    <div class="description-annonce">
      <div class="description">
        <div class="price">
          <h3 class="text-wine">{{annonce.prix | number }} €</h3>
          <hr class="border border-1 opacity-100 border-wine">
        </div>
        <p class="text-piece font-title"><b>{{annonce.designation}}<br/>{{annonce.nbPieces}} pièce(s) - {{annonce.surfaceHabitable}} m²<br/>{{annonce.ville}} ({{annonce.departement}})</b></p>
        <p class="text-charge">Dont prix de vente : 585 000 €<br/>Dont HN* : 20 550 € (3.5%)<br/>Charge acquéreur</p>
      </div>
      <a href="/annonces-immobilieres/{{annonce.slug}}" class="fiche">
        <img class="icon" src="/assets/images/logo-plume.svg">
        <p class="text-wine">VOIR LA FICHE</p>
        <hr class="border border-1 opacity-100 border-wine">
      </a>
    </div>
  </div>

</section>

<app-footer></app-footer>

