import { Component } from '@angular/core';
import { faHouse, faRing, faBaby, faBolt, faPerson, faPersonDress, faHandHoldingHand, faHandsHoldingChild, faScroll, faKey, faEarthEurope, faBriefcase, faHouseUser, faChartLine, faSackDollar, faCalculator, faHouseCircleCheck, faCoins } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  faHouse = faHouse;
  faCoins = faCoins;
  faRing = faRing;
  faHouseCircleCheck = faHouseCircleCheck
  faBaby = faBaby;
  faBolt = faBolt
  faPerson = faPerson;
  faPersonDress = faPersonDress;
  faHandHoldingHand = faHandHoldingHand;
  faHandsHoldingChild = faHandsHoldingChild;
  faScroll = faScroll;
  faKey = faKey;
  faEarthEurope = faEarthEurope;
  faBriefcase = faBriefcase;
  faHouseUser = faHouseUser;
  faChartLine = faChartLine;
  faSackDollar = faSackDollar;
  faCalculator = faCalculator;

// TODO 3 et dernier


}
