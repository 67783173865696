import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dpe-ges',
  templateUrl: './dpe-ges.component.html',
  styleUrls: ['./dpe-ges.component.scss']
})
export class DpeGesComponent implements OnInit {

  @Input() dpeValue: number | null = null;
  @Input() gesValue: number | null = null;
  letter: string = '';
  letter_list = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];
  options = {
    label_none: 'DPE non communiqué',
    label_mini: 'Bâtiment économe',
    label_ges_min: 'Faible émission de GES*',
    label_maxi: 'Bâtiment énergivore',
    label_ges_max: 'Forte émission de GES*',
    label_legend: 'en kWh/m².an',
    label_legend_ges: '* Gaz à effet de serre en KgeqCO2/m².an',
    image_dir: '/assets/images/',
    height: 26,
    width: 330,
    width2: 171,
  };
  constructor() { }

  ngOnInit(): void {

  }

  getDpeLetter(): string {
    let letter = '';
    if (this.dpeValue !== null) {
      if (this.dpeValue <= 50) {
        letter = 'b';
      } else if (this.dpeValue <= 90) {
        letter = 'c';
      } else if (this.dpeValue <= 150) {
        letter = 'c';
      } else if (this.dpeValue <= 230) {
        letter = 'd';
      } else if (this.dpeValue <= 330) {
        letter = 'e';
      } else if (this.dpeValue <= 450) {
        letter = 'f';
      } else {
        letter = 'g';
      }
    }
    return letter;
  }

  getDpeImagePath(lettre: string): string {
    const prefix = 'dpe-';
    const suffix = '.png';
    const selected_token = '-v';
    const isSelected = lettre === this.getDpeLetter();
    return `${this.options.image_dir}dpe/${prefix}${lettre}${isSelected ? '' : selected_token}${suffix}`;
  }


  getGesLetter(): string {
    let letter = '';
    if (this.gesValue !== null) {
      if (this.gesValue <= 5) {
        letter = 'b';
      } else if (this.gesValue <= 10) {
        letter = 'c';
      } else if (this.gesValue <= 20) {
        letter = 'c';
      } else if (this.gesValue <= 35) {
        letter = 'd';
      } else if (this.gesValue <= 55) {
        letter = 'e';
      } else if (this.gesValue <= 80) {
        letter = 'f';
      } else {
        letter = 'g';
      }
    }
    return letter;
  }

  getGesImagePath(lettre: string): string {
    const prefix = 'ges-';
    const suffix = '.png';
    const selected_token = '-v';
    const isSelected = lettre === this.getGesLetter();
    return `${this.options.image_dir}ges-couleur/${prefix}${lettre}${isSelected ? '' : selected_token}${suffix}`;
  }

}
