<app-header></app-header>

<section class="section-title">
  <div class="background-title">
    <h1 class="title text-white font-title">{{actu.title | uppercase}}</h1>
    <img class="icon" src="/assets/images/logo-plume.svg">
  </div>
</section>

<section class="section-block">
  <div class="block-button">
    <a href="/actualites" class="button-actus text-white responsive-none">Retour aux actualites</a>
  </div>
  <img class="block-image" [src]="actu.image">
  <div class="block-text">
    <p class="text-dark-wine" [innerHTML]="actu.content | sanitizeHtml"></p>
  </div>
  <div *ngIf="actu.link" class="block-button d-flex justify-content-center">
    <a [href]="actu.link" target="_blank" class="button-actus text-white responsive-none">Lire la suite sur notaires.fr</a>
  </div>
</section>

<app-footer></app-footer>
