import { Component } from '@angular/core';
import { BiensService } from 'src/app/_services/biens.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  annonces: any  = [];

  constructor(
    private biensService: BiensService,
  ) {
  }

  ngOnInit(): void {
    this.biensService.getBiensHomeCarousel().subscribe(data => {
      this.annonces = data;
    })
  }
}
