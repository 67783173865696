import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const API = `${environment.api}/api`;

@Injectable({
  providedIn: 'root'
})
export class BiensService {

  constructor(private http: HttpClient) { }

  getAllBiens(transaction: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/annonces/displayed?transaction=' + transaction, httpOptions);
  }

  getBien(slug: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/offre/' + slug, httpOptions);
  }

  getBiensHomeCarousel(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/annonces/homeCarousel', httpOptions);
  }

}
