import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { OfficeComponent } from './pages/office/office.component';
import { AnnoncesImmobilieresComponent } from './pages/annonces-immobilieres/annonces-immobilieres.component';
import { ExpertisesComponent } from './pages/expertises/expertises.component';
import { ServicesComponent } from './pages/services/services.component';
import { ActualitesComponent } from './pages/actualites/actualites.component';
import { TarifsComponent } from './pages/tarifs/tarifs.component';
import { DossierComponent } from './pages/dossier/dossier.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FormsModule } from "@angular/forms";
import { HttpClientModule} from '@angular/common/http';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { ActualitesDetailComponent } from './pages/actualites-detail/actualites-detail.component';
import { SanitizeHtml } from './_pipe/sanitizeHtml';
import { Pagination } from './_pipe/pagination';
import { AnnoncesImmobilieresDetailComponent } from './pages/annonces-immobilieres-detail/annonces-immobilieres-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleMapsModule } from '@angular/google-maps';
import { DpeGesComponent } from './pages/annonces-immobilieres-detail/dpe-ges/dpe-ges.component';


registerLocaleData(localeFr);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    OfficeComponent,
    AnnoncesImmobilieresComponent,
    ExpertisesComponent,
    ServicesComponent,
    ActualitesComponent,
    TarifsComponent,
    DossierComponent,
    ContactComponent,
    PageNotFoundComponent,
    MentionsLegalesComponent,
    ActualitesDetailComponent,
    SanitizeHtml,
    Pagination,
    AnnoncesImmobilieresDetailComponent,
    DpeGesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    GoogleMapsModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
