import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const API = `${environment.api}/api`;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendContact(formData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
      })
    };

    var data = {
      'nom': formData.name,
      'prenom': formData.firstName,
      'telephone': formData.phone,
      'email': formData.email,
      'codePostal': formData.postalCode,
      'message': formData.content
    }
    const body = data;

    return this.http.post(API + '/contact', body, httpOptions);

  }

}
