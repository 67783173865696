import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BiensService } from 'src/app/_services/biens.service';

@Component({
  selector: 'app-annonces-immobilieres-detail',
  templateUrl: './annonces-immobilieres-detail.component.html',
  styleUrls: ['./annonces-immobilieres-detail.component.scss']
})
export class AnnoncesImmobilieresDetailComponent {

  annonce: any  = [];
  slug: any;

  constructor(
    private biensService: BiensService,
    private route: ActivatedRoute,
  ) {
    this.slug = this.route.snapshot.paramMap.get('slug');
  }

  ngOnInit(): void {
    this.biensService.getBien(this.slug).subscribe(data => {
      this.annonce = data;
      if (this.annonce.transaction == 'vente'){
        this.annonce.transaction = "à vendre";
      } else {
        this.annonce.transaction = "à louer";
      }
    })
  }
}
