<nav class="navbar navbar-expand-xxl bg-body-tertiary navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src="/assets/images/logo.svg"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item" [class.active]="router.url==='/office'">
          <a class="nav-link" href="/office">L'OFFICE</a>
        </li>
        <li class="nav-item" [class.active]="router.url==='/annonces-immobilieres'">
          <a class="nav-link" href="/annonces-immobilieres">ANNONCES IMMOBILIERES</a>
        </li>
        <li class="nav-item" [class.active]="router.url==='/expertises'">
          <a class="nav-link" href="/expertises" >EXPERTISES</a>
        </li>
        <li class="nav-item" [class.active]="router.url==='/services'">
          <a class="nav-link" href="/services">SERVICES</a>
        </li>
        <li class="nav-item" [class.active]="router.url==='/actualites'">
          <a class="nav-link" href="/actualites">ACTU</a>
        </li>
        <li class="nav-item" [class.active]="router.url==='/tarifs'">
          <a class="nav-link" href="/tarifs">TARIFS</a>
        </li>
        <li class="nav-item" [class.active]="router.url==='/dossier'">
          <a class="nav-link" href="/dossier">DOSSIER</a>
        </li>

        <li class="nav-item" [class.active]="router.url==='/contact'">
          <a class="nav-link" href="/contact">CONTACT</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
